<template>
    <div class="form-group select-form-group" :class="{
             'has-error': errors && errors.has(field),
             'has-notice': errors && errors.hasNotice(field),
         }">
        <label :for="field" :class="labelClasses" v-if="labelText">{{ labelText }} <i v-if="optional">{{$t(" - Optional")}}</i></label>
        <div :class="inputClasses">
            <Multiselect
              :id="field"
              class="multiselect-custom"
              :class="{'hide-first-child':field === 'personality_type' || field === 'personality-type'}"
              :options="options"
              :group-values="groupValues"
              :group-label="groupLabel"
              :placeholder="placeholder"
              :label="label"
              :value="value"
              :track-by="trackBy"
              :allow-empty="allowEmpty"
              :group-select="false"
              :multiple="multiple"
              @search-change="asyncFind"
              @input="$emit('input', $event)"
            >
            </Multiselect>
            <AppError v-if="errors" :errors="errors" :field="field"/>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AppError from '../AppError';

export default {
  props: {
    errors: {},
    label: {},
    labelText: {},
    groupLabel: {},
    groupValues: {},
    value: {},
    field: {},
    options: {},
    placeholder: {},
    inputClasses: {},
    labelClasses: { default: '' },
    allowEmpty: { default: false },
    multiple: { default: true },
    trackBy: { default: 'name' },
    searchParam: { default: '' },
    optional: { default: false },
  },
  components: {
    Multiselect,
    AppError,
  },
  methods: {
    asyncFind(search) {
      if (this.searchParam.length === 0) {
        return;
      }
      if (search && search.length > 1) {
        this.loading = true;
        const params = {
          search: escape(search),
        };
        this.$store.dispatch(this.searchParam, params)
          .then((res) => {
            this.options = res;
          })
          .catch((err) => {
            this.errors.set(err.response.data.errors);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.dispatch(this.searchParam)
          .then((res) => {
            this.options = res;
          })
          .catch((err) => {
            this.errors.set(err.response.data.errors);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
  },
};

</script>

<style lang="scss">
    @import "~vue-multiselect/dist/vue-multiselect.min.css";

.multiselect-custom.hide-first-child .multiselect__element:first-child{
  display: none!important;
}

</style>
